<template>
  <v-row v-scroll="onScroll">
    <!-- tab -->
    <v-col cols="12" class="px-0 pt-0 background" style="position:fixed !important; top: 60px; z-index: 5; min-width: 840px; max-width: calc(100% - 105px);" :style="scroll > 100 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15);' : ''">
      <v-tabs class="pt-5" v-model="selectedItem" :key="$store.state.base.isExpandNavigationDrawer" :optional="false">
        <v-tab
          v-for="(item, i) in currentList"
          :key="`${item.id}-${i}`"
          :to="((item || {}).children || []).length ? null : { name: item.route, params: $route.params}"
          :ripple="false"
          >
          <template v-if="!((item || {}).children || []).length">
            <div style="min-width: 20px">
              <v-img contain class="mr-1" :src="require(`@/assets/account/${item.svg}-icon${$route.name === item.route ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="14" />
            </div>
            {{item.text === 'SII' ? $t('generals.SII') : item.text}}
          </template>
          <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="10" :nudge-left="6" min-width="120" transition="slide-y-transition" v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-row class="ma-0" align="center" v-bind="attrs" v-on="on">
                <v-icon v-if="env !== 'ax'" size="18" left small>mdi-{{item.icon}}</v-icon>
                <div v-else style="min-width: 20px">
                  <v-img class="mr-1" contain :src="require(`@/assets/account/${item.svg}-icon${$route.name === item.route || ['AccountGroups', 'AccountMemberUpdate', 'AccountGroupsUpdate', 'AccountGroupsCreate'].includes($route.name) ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="14" />
                </div>
                {{item.text === 'SII' ? $t('generals.SII') : item.text}}
                <v-icon class="pa-0 ml-2" size="18" style="width: 12px !important;">mdi-chevron-down</v-icon>
              </v-row>
            </template>
            <v-list class="pa-0 bgSearch">
              <div v-for="(c, j) in item.children" :key="`${i}-${j}`">
                <v-list-item class="px-3" active-class="transparent" :to="((c || {}).route || {}).length ? { name: c.route, params: $route.params } : ''" :ripple="false" :disabled="c.disabled" @click="selectedItem = 2">
                  <v-list-item-title class="body-2">{{c.text}}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </v-tab>
      </v-tabs>
    </v-col>
    <!-- end tab -->
    <v-col class="px-0" style="margin-top: 60px;">
      <router-view />
    </v-col>
  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
export default {
  components: {
    MiniHeader
  },
  data: () => ({
    scroll: 0,
    selectedItem: 0,
    current: '',
    list: [
      {
        text: 'Organización',
        route: 'AccountBasic',
        icon: 'domain',
        svg: 'account',
        disabled: false,
        use: ['ax', 'fc']
        // children: [
        //   {
        //     text: 'Cuenta',
        //     route: 'AccountBasic',
        //     parent: 'Organización',
        //     disabled: false,
        //     use: ['ax', 'fc']
        //   },
        //   {
        //     text: 'Pagos',
        //     route: 'DocumentsList',
        //     parent: 'Organización',
        //     disabled: true,
        //     use: ['ax']
        //   }
        // ]
      },
      {
        text: 'SII',
        route: 'IrsBuiltInSettings',
        icon: 'bank',
        svg: 'sii',
        disabled: false,
        use: ['ax', 'fc']
      },
      // {
      //   text: 'Emisión',
      //   disabled: false,
      //   route: 'CustomerMaster',
      //   use: ['ax']
      // },
      // {
      //   text: 'Recepción',
      //   disabled: false,
      //   route: 'AccountReceipt',
      //   use: ['ax']
      // },
      // {
      //   text: 'Notificaciones',
      //   disabled: false,
      //   route: 'AccountNotifications',
      //   use: ['ax'],
      //   showSupport: true
      // },
      // {
      //   text: 'Directorio',
      //   route: 'AccountMembers',
      //   disabled: false,
      //   use: ['ax', 'fc']
      // },
      {
        text: 'Directorio',
        route: 'AccountMembers',
        icon: 'account-group',
        disabled: false,
        svg: 'members',
        use: ['ax', 'fc'],
        dependentPaths: ['AccountGroupsCreate', 'AccountMembersRetrieve', 'AccountMemberUpdate', 'AccountGroups', 'AccountMembers', 'AccountGroupsRetrieve', 'AccountGroupsUpdate'],
        children: [
          {
            text: 'Usuarios',
            disabled: false,
            route: 'AccountMembers',
            parent: 'Directorio',
            use: ['ax', 'fc']
          },
          {
            text: 'Roles',
            disabled: false,
            route: 'AccountGroups',
            parent: 'Directorio',
            use: ['ax', 'fc']
          }
        ]
      },
      {
        id: 6,
        text: 'Developers',
        icon: 'xml',
        disabled: false,
        svg: 'developers',
        route: 'AccountSettingsWebhooksListCreate',
        use: ['ax', 'fc']
      },
      {
        id: 7,
        text: 'POS',
        icon: 'store',
        disabled: false,
        svg: 'pos',
        route: 'AccountSendLogPos',
        use: ['ax']
      },
      {
        id: 8,
        text: 'Notificaciones',
        icon: 'notification',
        disabled: false,
        svg: 'notification',
        route: 'AccountNotifications',
        use: ['ax', 'fc']
      }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    currentList () {
      return this.list?.filter(({use, showSupport}) => use?.some((v) => {
        if (this.env === 'ax' && this.$store.state.auth.userAccount.is_staff) {
          return (v === this.env || showSupport)
        }
        return (v === this.env && !showSupport)
      })) ?? []
    }
  },
  watch: {
    current (val) {
      this.selectedTab(this.current.parent)
    }
  },
  created () {
    this.selectedTab(this.$route.name, true)
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    selectedTab (route = '', isCreate = false) {
      const index = this.currentList.findIndex((item) => (!isCreate ? item.text : item.route) === route)
      if (index !== -1) this.selectedItem = index
      else {
        const index = this.currentList.findIndex((item) => item?.dependentPaths?.includes(route))
        if (index !== -1) this.selectedItem = index
      }
    },
    goTo (route = '') {
      this.$router.push({ name: route}).catch(err => err)
    }
  }
}
</script>